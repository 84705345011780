<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">

                <div class="card card-top-head">
                    <div class="card-action card-action--with-title mb-0">
                        <div>
                            <a href="profile.html" class="btn btn-white"><i class="fe fe-arrow-left mr-2"></i>Kembali</a>
                        </div>
                        <div class="card-action--title ml-3">
                            <div class="font-weight-bolder text-md">Pengaturan Profil</div>
                        </div>
                    </div>
                </div>


                <!-- Form Profile -->
                <div class="d-none card shadow-sm">
                    <div class="card-body">
                        <form class="mb-3">

                            <div class="row">
                                <div class="col-12 col-md-6">

                                    <!-- First name -->
                                    <div class="form-group">

                                        <!-- Label -->
                                        <label>
                                            Nama depan
                                        </label>

                                        <!-- Input -->
                                        <input type="text" class="form-control">

                                    </div>

                                </div>
                                <div class="col-12 col-md-6">

                                    <!-- Last name -->
                                    <div class="form-group">

                                        <!-- Label -->
                                        <label>
                                            Nama belakang
                                        </label>

                                        <!-- Input -->
                                        <input type="text" class="form-control">

                                    </div>

                                </div>
                                <div class="col-12">

                                    <!-- Email address -->
                                    <div class="form-group">

                                        <!-- Label -->
                                        <label class="mb-1">
                                            Alamat email
                                        </label>

                                        <!-- Form text -->
                                        <small class="form-text text-muted">
                                            Kontak ini akan diperlihatkan kepada orang lain di depan umum, jadi
                                            pilihlah dengan cermat.
                                        </small>

                                        <!-- Input -->
                                        <input type="email" class="form-control">

                                    </div>

                                </div>
                                <div class="col-12 col-md-6">

                                    <!-- Phone -->
                                    <div class="form-group">

                                        <!-- Label -->
                                        <label>
                                            Nomor telepon
                                        </label>

                                        <!-- Input -->
                                        <input type="text" class="form-control mb-3" placeholder="(___)___-____-___" data-mask="(000) 000-0000-000" autocomplete="off" maxlength="18">

                                    </div>

                                </div>
                                <div class="col-12 col-md-6">

                                    <!-- Birthday -->
                                    <div class="form-group">

                                        <!-- Label -->
                                        <label>
                                            Tanggal lahir
                                        </label>

                                        <!-- Input -->
                                        <input type="text" class="form-control flatpickr-input" data-toggle="flatpickr" readonly="readonly">

                                    </div>

                                </div>
                            </div> <!-- / .row -->

                            <!-- Divider -->
                            <hr class="mt-4 mb-5">

                            <div class="row">
                                <div class="col-12 col-md-6">

                                    <!-- Public profile -->
                                    <div class="form-group">

                                        <!-- Label -->
                                        <label class="mb-1">
                                            Profil Publik
                                        </label>

                                        <!-- Form text -->
                                        <small class="form-text text-muted">
                                            Membuat profil Anda menjadi publik berarti bahwa siapa pun di
                                            jaringan Scola akan dapat menemukan Anda.
                                        </small>

                                        <div class="row">
                                            <div class="col-auto">

                                                <!-- Switch -->
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="switchOne">
                                                    <label class="custom-control-label" for="switchOne"></label>
                                                </div>

                                            </div>
                                        </div> <!-- / .row -->
                                    </div>

                                </div>
                            </div> <!-- / .row -->
                            <!-- Submit -->
                            <button type="submit" class="btn btn-primary lift">
                                Perbarui Profil
                            </button>
                        </form>
                    </div>
                </div>

                <!-- Form Password -->
                <div class="d-none card shadow-sm">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-12 col-md-6 order-md-2">

                                <!-- Card -->
                                <div class="card bg-light ml-md-4">
                                    <div class="card-body">

                                        <!-- Text -->
                                        <p class="mb-2">
                                            Persyaratan kata sandi
                                        </p>

                                        <!-- Text -->
                                        <p class="small mb-2">
                                            Untuk membuat kata sandi baru, Anda harus memenuhi semua persyaratan
                                            berikut:
                                        </p>

                                        <!-- List group -->
                                        <ul class="small pl-4 mb-0">
                                            <li> Minimal 8 karakter</li>
                                            <li>Setidaknya satu karakter khusus</li>
                                            <li>Setidaknya satu nomor</li>
                                            <li>Tidak bisa sama dengan kata sandi sebelumnya</li>
                                        </ul>

                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-md-6">

                                <!-- Password -->
                                <div class="form-group">

                                    <!-- Label -->
                                    <label>
                                        Kata sandi
                                    </label>

                                    <!-- Input -->
                                    <input type="password" class="form-control">

                                </div>

                                <!-- New password -->
                                <div class="form-group">

                                    <!-- Label -->
                                    <label>
                                        Kata sandi baru
                                    </label>

                                    <!-- Input -->
                                    <input type="password" class="form-control">

                                </div>

                                <!-- Confirm new password -->
                                <div class="form-group">

                                    <!-- Label -->
                                    <label>
                                        Konfirmasi kata sandi
                                    </label>

                                    <!-- Input -->
                                    <input type="password" class="form-control">

                                </div>

                                <!-- Submit -->
                                <button type="submit" class="btn btn-primary lift">
                                    Perbarui Kata Sandi
                                </button>

                            </div>
                        </div> <!-- / .row -->


                    </div>
                </div>

                <!-- Form Two Factor -->
                <div class="card shadow-sm" v-if="!security.status">
                    <div class="card-body">
                        <form class="mb-3" v-on:submit.prevent="enable">

                            <div class="row">
                                <div class="row justify-content-center">
                                    <div class="col-md-10 py-2">
                                        <div class="row justify-content-center mt-3">
                                            <div class="col-md-9">
                                                <img src="https://scolacdn.com/images/autentikasi-value-2.jpg" class="w-100">
                                            </div>
                                        </div>
                                        <div class="d-block font-weight-600 text-center pb-4 border-bottom mb-4 pt-4 text-xsm">Aktivasi Google Authenticator</div>
                                        <div class="d-flex align-items-center mb-3">
                                            <div class="mr-2 question-number">
                                                <div class="question-number--item bg-primary text-white">1</div>
                                            </div>
                                            <div class="text-sm">
                                                <span class="font-weight-600">Scan barcode</span> di bawah ini dengan ponsel yang sudah terpasang aplikasi Google Authenticator. Anda bisa unduh aplikasi Android di <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en_us">Android Play</a> Store atau iOS di <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605">App Store</a>
                                            </div>
                                        </div>
                                        <div class="d-block text-center mb-4">
                                            <img v-bind:src="security.qrcode">
                                            <div class="d-block text-sm text-muted">Tidak bisa scan? Coba masukkan kode secara manual</div>
                                            <div class="d-block font-weight-600 binding">{{security.secret}}</div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <div class="mr-2 question-number">
                                                <div class="question-number--item bg-primary text-white">2</div>
                                            </div>
                                            <div class="text-sm">
                                                Masukkan 6 digit <span class="font-weight-600">kode autentikasi</span> yang dihasilkan.
                                            </div>
                                        </div>
                                        <div class="d-block bg-light p-3 rounded">
                                            <div class="row justify-content-center">
                                                <div class="col-md-8">
                                                    <div class="form-group row m-0 text-center">
                                                        <label class="col-sm-12 col-form-label col-form-label-thick">Kode Autentikasi</label>
                                                        <div class="col-sm-12">
                                                            <input type="name" name="verification" placeholder="Masukan kode verifikasi" class="form-control text-center" v-model="form.verification">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="mt-4 mb-5">
                            <!-- Submit -->
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary lift">
                                    Aktifkan two-factor authenticator
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="card shadow-sm" v-if="security.status">
                    <div class="card-block">
                        <form class="mb-3" v-on:submit.prevent="disable">
                            <div class="d-block text-center py-4">
                                <img src="https://scolacdn.com/images/protect.svg" style="width:120px;">
                                <div class="d-block text-xsm font-weight-600">Two-Factor Authentication sudah terpasang!</div>
                                <div class="small text-muted">Akun Anda terjaga keamanannya 👍</div>
                            </div>
                            <div class="row justify-content-center mb-3">
                                <div class="col-md-8 py-2">
                                    <div class="d-block p-3 bg-light rounded">
                                        <div class="d-block font-weight-600 mb-3">Nonaktifkan autentikasi?</div>
                                        <div class="d-block text-sm mb-3 pb-3 border-bottom">
                                            Menonaktifkan/mematikan Two-Factor Authentication akan memberikan kelemahan proteksi terhadap akun anda sekarang.
                                        </div>
                                        <div class="form-group row mb-2">
                                            <label class="col-sm-12 col-form-label col-form-label-thick">Kata Sandi Sekarang</label>
                                            <div class="col-sm-12">
                                                <input type="password" name="current_password" class="form-control" value="" v-model="form.current_password">
                                            </div>
                                        </div>
                                        <div class="d-flex text-xs">
                                            <div><i class="icofont icofont-warning mr-2 text-muted"></i></div>
                                            <div>Masukan kata sandi anda untuk menonaktifkan Two-Factor Authentication.</div>
                                        </div>

                                        <div class="d-flex justify-content-center pt-3">
                                            <button type="submit" class="btn btn-white btn-sm">Nonaktifkan Two-Factor Authentication</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import $ from 'jquery';

    export default {
        name: 'settings',
        data() {
            return {
                baseUrl: this.$apiconfig,
                security: {},
                form: { verification: '', current_password: '' },
            }
        },
        validations: {
            form: {
                verification: { required }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            enable: function() {
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    let formData = new FormData();

                    formData.append('method', 'totp');
                    formData.append('secret', this.security.secret);
                    formData.append('verification', this.form.verification);


                    this.$http.post(this.baseUrl + 'admin/security/enable_2fa', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push('/');
                                        location.reload();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function() {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            return;
                        });
                }
            },
            disable: function() {
                let formData = new FormData();
                formData.append('current_password', this.form.current_password);
                this.$http.post(this.baseUrl + 'admin/security/disable_2fa', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push('/')
                                    location.reload();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                    .catch(function() {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        return;
                    });
            },
            getData: function() {
                this.fetching = true;

                let uri = this.baseUrl + 'admin/security/generate_secret';
                this.$http.get(uri, {}).then(res => {
                    let getResponse = res.data;
                    this.security = getResponse;
                    this.fetching = false;
                });
            },
        },
        metaInfo: {
            title: 'Pengaturan',
        },
    }
    $(function() {
        $("#myBtn").click(function() {
            $("#myModal").modal('show');
            // alert('test');
        });
    });
</script>